import React, { useState, useEffect, useContext, PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { Amplify } from "aws-amplify";
import {
  signIn as authSignIn,
  signOut as authSignOut,
  fetchAuthSession,
  JWT,
  confirmSignIn,
  updateUserAttributes,
  resetPassword as authResetPassword,
  confirmResetPassword,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  setUpTOTP,
  verifyTOTPSetup,
  updateMFAPreference,
  fetchMFAPreference,
  rememberDevice as authRememberDevice,
  fetchDevices,
  FetchDevicesOutput,
  forgetDevice as authForgetDevice,
  updatePassword as authUpdatePassword
} from 'aws-amplify/auth';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_CLIENT_ID!,
      userPoolId: process.env.REACT_APP_USER_POOL_ID!
    }
  }
})

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo: {
    accessToken: JWT;
    idToken: JWT;
  } | null;
  userAttributes: FetchUserAttributesOutput;
  authStatus: AuthStatus;
  mfaEnabled: boolean;
  rememberedDevices: FetchDevicesOutput;
  signIn: (email: string, password: string, redirectUrl?: string) => Promise<void>;
  register: (
    newPassword: string,
    userAttributes: {
      given_name: string;
      family_name: string;
      phone_number?: string;
      "custom:job_title": string;
    }
  ) => Promise<void>;
  signOut: () => Promise<void>;
  setupMFA: () => Promise<string>;
  confirmMFASetup: (otp: string) => Promise<void>;
  verifyOTP: (otp: string, rememberDevice?: boolean) => Promise<void>;
  sendResetPasswordCode: (email: string) => Promise<void>;
  resetPassword: (code: string, email: string, newPassword: string) => Promise<void>;
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  forgetDevice: (deviceKey: string) => Promise<void>;
  refreshAuth: () => Promise<void>;
}

export const AuthContext = React.createContext<IAuth | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export const AuthIsSignedIn: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { authStatus } = useContext(AuthContext) as IAuth;

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { authStatus } = useContext(AuthContext) as IAuth;

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState<IAuth["sessionInfo"]>(null);
  const [userAttributes, setUserAttributes] = useState<FetchUserAttributesOutput>({});
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(false);
  const [rememberedDevices, setRememberedDevices] = useState<FetchDevicesOutput>([]);

  async function getSession() {
    try {
      const { tokens } = await fetchAuthSession({ forceRefresh: true });

      if (tokens && tokens.accessToken && tokens.idToken) {
        setSessionInfo({ accessToken: tokens.accessToken, idToken: tokens.idToken })

        const attributes = await fetchUserAttributes();
        setUserAttributes(attributes);

        const mfaPreference = await fetchMFAPreference();
        if (mfaPreference.enabled?.includes("TOTP")) {
          setMfaEnabled(true);
        }

        const devices = await fetchDevices();
        setRememberedDevices(devices);

        setAuthStatus(AuthStatus.SignedIn)
      } else {
        setAuthStatus(AuthStatus.SignedOut);
      }
    } catch (err) {
      console.error(err)
      setAuthStatus(AuthStatus.SignedOut)
    }
  }

  useEffect(() => {
    getSession();
  }, [])

  async function refreshAuth() {
    await getSession();
  }

  async function signIn(email: string, password: string, redirectUrl?: string) {
    try {
      const { isSignedIn, nextStep } = await authSignIn({ username: email, password });

      if (isSignedIn && nextStep.signInStep === "DONE") {
        setAuthStatus(AuthStatus.SignedIn)
        getSession();
      }

      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        navigate(`/registration${redirectUrl ? `?redirectUrl=${redirectUrl}` : ""}`);
        return;
      }

      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
        navigate(`/mfa${redirectUrl ? `?redirectUrl=${redirectUrl}` : ""}`)
      }

      if(redirectUrl) {
        navigate(redirectUrl)
      }
    } catch (err) {
      console.error('Error signing out: ', err);
      throw err;
    }
  }

  async function signOut() {
    try {
      await authSignOut();
      getSession();
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  }

  async function register(
    newPassword: string,
    userAttributes: { given_name: string; family_name: string; phone_number?: string; "custom:job_title": string }
  ) {
    try {
      await confirmSignIn({
        challengeResponse: newPassword
      });

      await updateUserAttributes({
        userAttributes
      });

      await getSession();
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async function sendResetPasswordCode(email: string) {
    await authResetPassword({ username: email })
  }

  async function resetPassword(code: string, email: string, newPassword: string) {
    await confirmResetPassword({
      confirmationCode: code,
      username: email,
      newPassword
    });
  }

  async function updatePassword(oldPassword: string, newPassword: string) {
    await authUpdatePassword({
      oldPassword,
      newPassword
    });
  }

  // Gets secret code to enable MFA
  async function setupMFA() {
    const { sharedSecret } = await setUpTOTP();
    return sharedSecret;
  }

  // Confirm new MFA setup
  async function confirmMFASetup(otp: string) {
    await verifyTOTPSetup({ code: otp });
    await updateMFAPreference({ totp: 'PREFERRED' });
    getSession();
  }

  async function verifyOTP(otp: string, rememberDevice?: boolean) {
    try {
      await confirmSignIn({
        challengeResponse: otp
      });

      if(rememberDevice) {
        await authRememberDevice();
      };

      getSession();
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async function forgetDevice(deviceKey: string) {
    await authForgetDevice({ 
      device: {
        id: deviceKey
      }
    });
  }

  return <AuthContext.Provider value={{
    authStatus,
    sessionInfo,
    userAttributes,
    mfaEnabled,
    rememberedDevices,
    signIn,
    signOut,
    register,
    setupMFA,
    verifyOTP,
    confirmMFASetup,
    sendResetPasswordCode,
    resetPassword,
    updatePassword,
    forgetDevice,
    refreshAuth
  }}>{children}</AuthContext.Provider>;
};


export default AuthProvider;
